<template>
	<model
	model_name="discount"></model>
</template>
<script>
export default {
	components: {
		Model: () => import('@/common-vue/components/ModalModel')
	},
}
</script>